/********** Template CSS **********/
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";

:root {
    --primary: #5B8C51;
    --secondary: #EDDD5E;
    --light: #F7F7F7;
    --dark: #404A3D;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-medium {
    font-weight: 600 !important;
}

.fw-semi-bold {
    font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    transition: .5s;
    font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
    color: var(--light);
}

.btn-secondary,
.btn-outline-secondary:hover {
    color: var(--dark);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-nav .nav-link {
    margin-right: 35px;
    padding: 25px 0;
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba(12, 11, 11, 0.536);
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    background-color: var(--primary);
    border: 10px solid var(--primary);
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    background: url('../img/backgroundimage.jpg') center center no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
    font-size: 18px;
    color: var(--light);
}


/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
}

.section-title::before {
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: 2px;
    top: 5px;
    left: -40px;
    background: var(--primary);
    z-index: -1;
}

.section-title::after {
    position: absolute;
    content: "";
    width: calc(100% + 120px);
    height: 2px;
    bottom: 6px;
    left: -60px;
    background: var(--primary);
    z-index: -1;
}

.section-title.text-start::before {
    width: calc(100% + 40px);
    left: 0;
}

.section-title.text-start::after {
    width: calc(100% + 60px);
    left: 0;
}


/*** About ***/
.about-experience {
    position: absolute;
    width: 100%;
    height: 100%;
    right: -45px;
    bottom: -45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/*** Service ***/
.service-item {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item .service-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    overflow: hidden;
    z-index: -1;
}

.service-item .service-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    border-radius: 10px;
}

.service-item .service-img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    z-index: 1;
}

.service-item .service-img::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFFFFF;
    transition: .5s ease-out;
    z-index: 2;
}

.service-item:hover .service-img::after {
    width: 0;
    left: auto;
    right: 0;
}

.service-item .service-text .service-icon {
    width: 140px;
    height: 140px;
    padding: 15px;
    margin-top: -70px;
    margin-bottom: 40px;
    background: #FFFFFF;
    border-radius: 140px;
    overflow: hidden;
    box-shadow: 0 0 60px rgba(0, 0, 0, .1);
}

.service-item .service-text h5,
.service-item .service-text p {
    transition: .5s;
}

.service-item:hover .service-text h5,
.service-item:hover .service-text p {
    color: #FFFFFF;
}

.service-item .service-text .btn {
    color: var(--secondary);
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .25);
}

.service-item .service-text .btn:hover {
    color: var(--dark);
    background: var(--secondary);
}


/*** Product ***/
.product-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.product-item .product-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    padding-top: 60px;
    transition: .5s;
}

.product-item:hover .product-overlay {
    opacity: 1;
    padding-top: 0;
}


/*** Team ***/
.team-item {
    position: relative;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.team-item .btn {
    border-color: transparent;
    box-shadow: 0 0 45px rgba(0, 0, 0, .2);
}


/*** Testimonial ***/
.testimonial-img {
    position: relative;
    min-height: 400px;
}

.testimonial-img::after {
    position: absolute;
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 200px;
    color: #EEEEEE;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.testimonial-img img {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.testimonial-img img:nth-child(1) {
    top: 0;
    left: 0;
}

.testimonial-img img:nth-child(2) {
    top: 60%;
    left: 20%;
}

.testimonial-img img:nth-child(3) {
    top: 20%;
    left: 60%;
}

.testimonial-img img:nth-child(4) {
    bottom: 0;
    right: 0;
}

.testimonial-img img .animated.pulse {
    animation-duration: 2s;
}

.testimonial-carousel .owl-item img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.testimonial-carousel .owl-nav {
    margin-top: 30px;
    display: flex;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    border-radius: 45px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .2);
    font-size: 22px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: var(--secondary);
    color: var(--dark);
}


/*** Footer ***/
.footer {
    color: #B0B9AE;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #B0B9AE;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--light);
    letter-spacing: 1px;
    box-shadow: none;
}

